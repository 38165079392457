import React from "react";
import logo from "./moonTM.png";
import moonFace from "./moon-face.png";

import "./App.css";
import {
  BtnContainer,
  Button,
  Container,
  Icon,
  Logo,
  LogoContainer,
  MoonFace,
  Video,
  VideoContainer,
  VideoWrapper,
} from "./App.styles";

// import jup from "./jupiter-logo.svg";
import ray from "./ray.png";
import birdeye from "./birdeye.png";
import tg from "./telegram.webp";
import twitter from "./twitter.png";

// const JUP_LINK =
//   "https://jup.ag/swap/SOL-moonCARzr9dYVGLiTxtMk443jNYZr8uiW8wkiMTcFtk";

const RAYDIUM_LINK =
  "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=moonCARzr9dYVGLiTxtMk443jNYZr8uiW8wkiMTcFtk&inputAmount=1";

const BIRDEYE_LINK =
  "https://birdeye.so/token/moonCARzr9dYVGLiTxtMk443jNYZr8uiW8wkiMTcFtk?chain=solana";
const TG_LINK = "https://t.me/moontmportal";
const TWITTER_LINK = "https://twitter.com/moontrademark";

function App() {
  return (
    <Container>
      <VideoContainer>
        <VideoWrapper>
          <Video
            loop
            autoPlay
            playsInline
            muted
            // poster={section1Poster}
          >
            <source src={"/bg.mp4"} type="video/mp4" />
          </Video>
        </VideoWrapper>
      </VideoContainer>

      <LogoContainer>
        <Logo src={logo} />
      </LogoContainer>

      <MoonFace src={moonFace} />

      <BtnContainer>
        {/* <Button onClick={() => window.open(JUP_LINK, "_blank")}>
          <Icon src={jup} />
          Trade
        </Button> */}

        <Button onClick={() => window.open(RAYDIUM_LINK, "_blank")}>
          <Icon src={ray} />
          Trade
        </Button>

        <Button onClick={() => window.open(BIRDEYE_LINK, "_blank")}>
          <Icon src={birdeye} />
          Chart
        </Button>

        <Button onClick={() => window.open(TG_LINK, "_blank")}>
          <Icon src={tg} />
          Telegram
        </Button>

        <Button onClick={() => window.open(TWITTER_LINK, "_blank")}>
          <Icon src={twitter} />
          Twitter
        </Button>
      </BtnContainer>
    </Container>
  );
}

export default App;
