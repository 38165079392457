import styled from "@emotion/styled";

export const DISPLAY_SIZES = {
  MOBILE_S: "320px",
  MOBILE_M: "375px",
  MOBILE_L: "425px",
  TABLET: "768px",
  LAPTOP: "1024px",
  LAPTOP_L: "1440px",
  DESKTOP: "1920px",
  DESKTOP_L: "2560px",
  DESKTOP_XL: "3840px",
};

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 48px;
`;

export const VideoContainer = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  /* border-radius: 32px; */
  /* border: 4px solid var(--grey-100); */
  /* box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.4); */
  overflow: hidden;
  /* margin-bottom: 80px; */
  /* opacity: 0.9; */
`;

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 1;
  display: flex;
`;

export const LogoContainer = styled.div`
  flex: 2;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 50vw;
  position: relative;

  /* margin: auto; */
  display: block;

  filter: drop-shadow(0px 0px 32px #fff);

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 80vw;
  }
`;

export const MoonFace = styled.img`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 1vw;
  right: 0;
  top: 12vh;
  height: 7vh;

  opacity: 0.5;

  animation: glow 2s infinite ease-in-out;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    top: 14vh;
  }

  @keyframes glow {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
    }
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  width: 60vw;
  flex: 1;

  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  margin: 0 auto;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 90vw;
  }
`;

export const Button = styled.button`
  @font-face {
    font-family: "Mont";
    src: url("/mont.otf");
  }

  font-family: Mont;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  /* line-height: 150%; */

  border: unset;
  outline: unset;

  border-radius: 8px;

  padding: 12px 24px;

  gap: 8px;

  cursor: pointer;

  transition: 0.2s background-color, 0.2s box-shadow;

  :hover {
    background-color: #222;

    box-shadow: 2px 2px 4px 0px rgba(255, 255, 255, 0.1);
  }
`;

export const Icon = styled.img`
  height: 24px;
  /* width: 24px; */
`;
